import { EsppHeader } from "src/app/modules/espp/espp-headers.enum";
import { LocalStorage } from "../Storage";
import { AppSettingStore } from "./app-settings";

interface QuoteDetailHeader { }

export class HeaderStore implements QuoteDetailHeader {

    get headers() {
        return AppSettingStore.getInstance().headers;
    }

    private get(key: EsppHeader): string | null {
        return this.headers[key]
    }

    get equipmentHeader(): string {
        return 'Equipment';
    }

    get customerNameHeader(): string {
        return this.get(EsppHeader.Customer_NAME) ?? 'Customer Name';
    }

    get quoteTypeHeader(): string {
        return this.get(EsppHeader.QUOTE_TYPE) ?? 'Quote Type';
    }

    get saleTypeHeader(): string {
        return this.get(EsppHeader.SALE_TYPE) ?? 'Sale Type';
    }

    get primaryApplicationHeader(): string {
        return this.get(EsppHeader.USE_TYPE) ?? 'Use Type';
    }

    get useTypeHeader(): string {
        return this.get(EsppHeader.USE_TYPE) ?? 'Use Type';
    }

    get pin17Header(): string {
        return this.get(EsppHeader.PIN_17) ?? 'Pin17';
    }

    get serialNumberHeader(): string {
        return this.get(EsppHeader.SERIAL_NUMBER) ?? 'Serial Number';
    }

    get ManufacturerHeader(): string {
        return this.get(EsppHeader.SERIAL_NUMBER) ?? 'Manufacturer';
    }

    get equipmentTypeHeader(): string {
        return this.get(EsppHeader.EQUIPMENT_TYPE) ?? 'Equipment Type';
    }

    get shipDateHeader(): string {
        return this.get(EsppHeader.SHIP_DATE) ?? 'Ship Date';
    }

    get deliveryDateHeader(): string {
        return this.get(EsppHeader.DELIVERY_DATE) ?? 'Standard Warranty Start Date';
    }

    get warrantyTermeHeader(): string {
        return this.get(EsppHeader.WARRANTY_TERM) ?? 'Warranty Term';
    }
    
    get warranhtyStartHeader(): string {
        return this.get(EsppHeader.WARRANTY_EFFECTIVE) ?? 'Warranty Start Date';
    }
    get warrantyExpirationHeader(): string {
        return this.get(EsppHeader.WARRANTY_EXPIRATION) ?? 'Warranty Expiration Date';
    }

    get coverageTermHeader(): string {
        return this.get(EsppHeader.COVERGAE_TERM) ?? 'Plan Term';
    }

    get currentHoursHeader(): string {
        return this.get(EsppHeader.CURRENT_HOURS) ?? 'Current Hours';
    }

    get retailValueHeader(): string {
        return this.get(EsppHeader.RETAIL_VALUE) ?? 'Retail Value';
    }

    get quoteExpirationHeader(): string {
        return this.get(EsppHeader.QUOTE_EXPIRATION) ?? 'Quote Expiration';
    }

    get salesPersonNameHeader(): string {
        return this.get(EsppHeader.SALESPERSON_NAME) ?? 'Salesperson';
    }

    get programHeader(): string {
        return this.get(EsppHeader.PROGRAM) ?? 'Program';
    }

    get planTypeHeader(): string {
        return this.get(EsppHeader.PLAN_TYPE) ?? 'Plan Type';
    }

}