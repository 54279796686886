import { CursorError } from '@angular/compiler/src/ml_parser/lexer';
import { Injectable } from '@angular/core';
import { AbstractControl, FormControl, FormGroup, ValidationErrors, ValidatorFn } from '@angular/forms';
import { Observable, of } from 'rxjs';
import { debounceTime, distinctUntilChanged, take, map, catchError } from 'rxjs/operators';
import { EsppQuoteType } from 'src/app/core/enum/EsppQuoteType';
import { EsppComponent } from '../../../espp.component';
import { EsppQuoterComponent } from '../espp-quoter.component';
import { Pin17Service } from '../services/pin17/pin17.service'
import { UseTypeService } from '../services/use-type/use-type.service';

@Injectable()
export class EsppFormValidator {
    private static requiredControls = {
        'GLOBAL': [
            'QUOTE_TYPE',
            'SALE_TYPE',
            'SERIAL_NUMBER',
            'MANUFACTURER',
            'MODEL',
            'MODEL_YEAR'
        ],
        'VOLVO': {
            'ETE': [
                'PIN_17'
            ]
        }
    }

    private static isRequired(quoteType: EsppQuoteType, mfrProgram: string, controlKey: string): boolean {
        if (quoteType == EsppQuoteType.NONE) {
            return this.requiredControls['GLOBAL'].includes(controlKey) || Object.keys(this.requiredControls[mfrProgram]).includes(controlKey)
        }
        return this.requiredControls['GLOBAL'].includes(controlKey) || this.requiredControls[mfrProgram][quoteType].includes(controlKey)
    }

    public static required(quoteType: EsppQuoteType, mfrProgram: string, controlKey: string) {
        return (control: AbstractControl): ValidationErrors | null => {
            if (this.isRequired(quoteType, mfrProgram, controlKey)) {
                return !control.value ? { required: true } : null
            }
            return null;
        }
    }

    public static canQuote(useTypeService: UseTypeService) {
        return (group: AbstractControl): ValidationErrors | null => {
            const useType = group.get('use_type')?.value;
            if (!useType) return null;
            const useTypes = useTypeService.useTypes.value;
            const canQuote = useTypes.filter(type => type.use_type === useType)[0]
            return (canQuote && canQuote.can_quote) ? null : { canQuote: true };
        }
    }

    public static minLengthNullable(min: number) {
        return (control: AbstractControl): ValidationErrors | null => {
            if (!control.value) return null;
            return (control.value).length < min ? { minLength: true } : null;
        }
    }

    public static maxLengthNullable(min: number) {
        return (control: AbstractControl): ValidationErrors | null => {
            if (!control.value) return null;
            return (control.value).length < min ? { maxLength: true } : null;
        }
    }
}
const _MS_PER_DAY = 1000 * 60 * 60 * 24;
// a and b are javascript Date objects
function dayDiff(a, b) {
    if(!(a instanceof Date)) a = new Date(a);
    if(!(b instanceof Date)) b = new Date(b);
    // Discard the time and time-zone information.
    const utc1 = Date.UTC(a.getFullYear(), a.getMonth(), a.getDate());
    const utc2 = Date.UTC(b.getFullYear(), b.getMonth(), b.getDate());
  
    return Math.floor((utc2 - utc1) / _MS_PER_DAY);
  }
export function createShortBaseValidator(): ValidatorFn {
    return (group: AbstractControl): ValidationErrors | null => {
        const mfr = group.value.manufacturer;
        const quoteType = group.value.quote_type;
        const shipDate = group.value.ship_date;
        const deliveryDate = group.value.delivery_date;

        if (quoteType != EsppQuoteType.NEW || mfr != 'VOLVO') {
            return null;
        }

        if (!shipDate || !deliveryDate) {
            return null;
        }

        const diffDays = dayDiff(shipDate,deliveryDate);
        
        return (diffDays > 365) ? { pastShortBase: true } : null;
    }
}

export function createBaseHoursValidator(): ValidatorFn {
    return (group: AbstractControl): ValidationErrors | null => {

        const baseWarranty = group.value.warranty_term
        const currentHours = group.value.current_hours
        if (!baseWarranty) {
            return null;
        }

        if (baseWarranty.split('&').length == 0) {
            return null;
        }

        const warranties = baseWarranty.split('&')
            .map(
                coverage => coverage.match(/\d+/g)
            );


        let baseHours = 9999;
        let isUnlimited = warranties[0].length == 1;
        if (!isUnlimited) {
            baseHours = parseInt(warranties[0][1]);
        }

        return (parseInt(currentHours) > baseHours) ? { baseHours: true } : null;
    }
}


export function createMfrWarrrantyValidator(): ValidatorFn {
    return (group: AbstractControl): ValidationErrors | null => {

        const mfr = group.value.manufacturer;
        const quoteType = group.value.quote_type;
        //const shipDate = group.value.ship_date;
        const deliveryDate = group.value.delivery_date
        const baseEnd = group.value.warranty_expiration
        const baseWarranty = group.value.warranty_term

        if ((quoteType != EsppQuoteType.NEW && mfr !== 'VOLVO') || quoteType != EsppQuoteType.NEW) {
            return null;
        }

        if (!deliveryDate || !baseWarranty || !baseEnd) {
            return null;
        }

        const diffTime = Math.abs(baseEnd - deliveryDate);
        // const diffMonths = Math.ceil(diffTime / (2628000000));
        const diffMonths = monthDiff(deliveryDate,baseEnd)
        //console.log('diff month: ' ,diffMonths)

        const warranties = baseWarranty.split('&')
            .map(
                coverage => coverage.match(/\d+/g)
            );
        let baseTerm = parseInt(warranties[0][0]);
        return (diffMonths != baseTerm) ? { mfrBase: true } : null;
        //return (dayDiff(deliveryDate,baseEnd) > baseTerm*365) ? { mfrBase: true } : null;
    }
}

function checkLeapYear(year) {

    //three conditions to find out the leap year
    if ((0 == year % 4) && (0 != year % 100) || (0 == year % 400)) {
        console.log(year + ' is a leap year');
    } else {
        console.log(year + ' is not a leap year');
    }
}

function monthDiff(d1, d2) {
    var months;
    months = (d2.getFullYear() - d1.getFullYear()) * 12;
    months -= d1.getMonth();
    months += d2.getMonth();
    return months <= 0 ? 0 : months;
}

export function createBaseValidator(): ValidatorFn {
    return (group: AbstractControl): ValidationErrors | null => {

        const quoteDate = new Date();
        const baseEnd = group.value.warranty_expiration

        const baseWarranty = group.value.warranty_term

        if (!baseWarranty || !baseEnd) {
            return null;
        }

        return (quoteDate > baseEnd) ? { mfrBaseExpired: true } : null;
    }
}

export function createEquipmentStatusValidator(component: EsppQuoterComponent): ValidatorFn {
    //statusNotQuotable
    return (group: AbstractControl): ValidationErrors | null => {

        const quoteType = group.value.quote_type;
        const status = group.value.equipment_status

        if (component.equipmentStatuses) {
            const currentStatus = component.equipmentStatuses.find(s => s.id == status)
            if (currentStatus && quoteType == EsppQuoteType.USED) {
                return !currentStatus.can_quote ? { statusNotQuotable: true } : null;
            }
        }

        return null;
    }
}


export function createMaxHoursValidator(component: EsppQuoterComponent): ValidatorFn {
    //statusNotQuotable
    return (control: AbstractControl): ValidationErrors | null => {

        const currentHours = parseInt(control.value)

        return currentHours > component.maxHours ? {maxHours: true} : null;
    }
}